@import "./components/spacing/functions";
@import "./components/typography/mixins";
@import "./components/button/mixins";
@import "./components/footer/mixins";
@import "./components/modal/mixins";

/*
==================================================
    /Typography rules
==================================================
*/
.title-1 {
  @include title-3;
}

/*
==================================================
    /Contact page
==================================================
*/
.contact-title {
  @include title-4;
}

.contact-form-wrapper {
   @include title-fz-4;
   @include margin-y(200);

   & > form {
     font-size: 1rem;
   }
 }

/*
==================================================
    /Layout
==================================================
*/
.contained {
  padding-left: get-contained-side-spacing('sm');
  padding-right: get-contained-side-spacing('sm');
}

.contained-y {
  @include title-fz-3;
}

/*
==================================================
    /Button
==================================================
*/
.button {
  @include button-widget-sm;
}

/*
==================================================
    /Footer
==================================================
*/
.footer {
  @include footer-sm;
}

.footer-brand-container {
  @include footer-brand-container-sm;
}

.footer-nav {
  @include footer-nav-sm;
}

/*
==================================================
    /Home page
==================================================
*/
.landing-button {
  display: inline-block;
  margin: 0 auto;
}

.landing-container {
  text-align: center;
}

/*
==================================================
    /Home page/Alsace
==================================================
*/
.alsace-button-container {
  display: flex;
}

.alsace-button {
  width: calc(50% - 8px);
  margin-bottom: 0;
  margin-right: 8px;
  padding-left: 1em;
  padding-right: 1em;

  &:last-of-type {
    margin-bottom: 0;
    margin-left: 8px;
    margin-right: 0;
  }
}

/*
==================================================
    /Home page/Discover
==================================================
*/
.discover-card {
  @include title-fz-3;
  padding: get-spacing(200);
}

.discover-button {
  display: inline-block;
}

.discover-map {
  height: 40vh;
}

.discover {
  padding-top: 30vh;
}

/*
==================================================
    /Home page/Contact us
==================================================
*/
.contact-us-title {
  margin-bottom: get-spacing(50);
}

.contact-us-button {
  display: inline-block;
}

/*
==================================================
    /Home page/Houses
==================================================
*/
.card-container {
  & > .card:not(:last-of-type) {
    @include title-3;
    margin-bottom: get-spacing(150);
  }
}

.houses {
  width: calc(6 / 8 * 100%);
  margin: 0 auto;
}

.card-title {
  @include title-fz-4;
}

.card {
  margin-left: auto;
  margin-right: auto;
}

.card-button {
  display: inline-block;
}

/*
==================================================
    /Home page/Houses
==================================================
*/
.landing-title {
  @include title-fz-4;
}

/*
==================================================
    /Home page/Carousel
==================================================
*/

/*
==================================================
    /House/Details
==================================================
*/
.details-title {
  @include title-fz-4;
}

.details-button {
  display: inline-block;
}

/*
==================================================
    /House/Other houses
==================================================
*/
.other-house-title {
  @include title-fz-4;
}

.other-house-body {
  @include title-fz-4;
}

/*
==================================================
    /Modal
==================================================
*/
.modal {
  @include modal-sm;
}

.modal-title {
  @include modal-title-sm;
}
