@import "../typography/mixins";
@import "../spacing/functions";
@import "../button/mixins";

@mixin footer {
  background: rgba(0,0,0, .03);

  @include title-fz-5;
  padding-top: get-spacing(200);
  padding-bottom: get-spacing(200);
}

@mixin footer-brand-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
}

@mixin footer-slogan {
  @include body-2;
  line-height: 2em;
}

@mixin footer-brand {
  display: inline-block;

  & > img {
    height: 70px;
    display: block;
    width: auto;
  }
}

@mixin footer-nav {
  text-align: center;
}

@mixin footer-link {
  @include button;
}

@mixin footer-nav-list {
  @include body-fz-1;
}

@mixin footer-nav-item {
  &:not(:first-child):not(:last-child) {
    margin: get-spacing(125) 0;
  }
}

/*
==================================================
    /Small screens
==================================================
*/

@mixin footer-sm {
  display: flex;
  @include title-fz-4;
}

@mixin footer-brand-container-sm {
  display: block;
  text-align: left;
  width: 50%;
  padding-right: 16px;
  margin-right: 12.5%;
  box-sizing: border-box;
}

@mixin footer-nav-sm {
  text-align: left;
}


/*
==================================================
    /Medium screens
==================================================
*/
@mixin footer-brand-container-md {
  margin-left: calc(100% / 8 + 16px);
  width: calc(100% / 8 * 3);
}

/*
==================================================
    /Large screens
==================================================
*/
@mixin footer-brand-container-lg {
  margin-left: calc(100% / 12 * 3);
  width: calc(100% / 12 * 3);
  margin-right: 0;
  padding-right: 8px;
}

@mixin footer-lg {
  @include title-fz-3;
}

@mixin footer-nav-lg {
  padding-left: 8px;
}
