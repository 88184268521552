@import "../spacing/functions";
@import "../typography/mixins";

@mixin modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 10001;
  background: rgba(0,0,0,.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity .3s;

  &[data-modal-active="true"] {
    opacity: 1;
    pointer-events: all;

    & > .modal {
      transform: translateY(0);
    }
  }
}

@mixin modal {
  background: white;
  padding: get-contained-side-spacing('xs') / 2;
  margin: get-contained-side-spacing('xs') / 2;
  width: calc(100% - #{get-contained-side-spacing('xs')});
  box-sizing: border-box;
  transition: transform .3s;
  transform: translateY(-100%);
}

@mixin modal-header {
  display: flex;
  justify-content: space-between;
}

@mixin modal-title {
  @include title-6;
}

@mixin modal-sm {
  padding: get-contained-side-spacing('sm');
  margin: get-contained-side-spacing('sm');
}

@mixin modal-title-sm {
  @include title-fz-5;
}

@mixin modal-lg {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}