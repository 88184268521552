// Base font color for titles
$base-title-font-color: rgba(0, 0, 0, .87);

// Base font color for body
$base-body-font-color: rgba(0, 0, 0, .77);

// Font family for titles
$title-font-family: 'Playfair Display', serif;

// Font family for body
$body-font-family: 'Montserrat', sans-serif;

// Font family for brand logo
$brand-font-family: 'Charmonman', serif;
