@import "../colors/variables";
@import "../spacing/mixins";

@mixin button-widget {
  background: $primary-color;
  text-align: center;
  display: block;
  box-sizing: border-box;
  padding: 1.1em 4em;
  width: 100%;
  cursor: pointer;
}

@mixin button-outline-widget {
  @include button-widget;

  background: none;
  border: 1px solid $primary-color;
  color: $primary-color;

  display: inline-block;
  width: auto;
}
/*
==================================================
    /Small screens
==================================================
*/
@mixin button-widget-sm {
  width: auto;
}
